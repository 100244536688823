const network = {
    name: 'Juno',
    lcd_api: 'https://lcd.juno-1.bronbro.io',
    rpc_api: 'https://rpc.juno-1.bronbro.io',

    denom: 'ujuno',
    token_name: 'JUNO',
    chainId: 'juno-1',
    mintscanAlias: 'juno',
    prefix: 'juno',
    address_prefix: 'juno',
    exponent: 6,
    proposal_need: 250
}

export default network