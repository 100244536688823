export default {
    // English
    en: {
        message: {
            // Page title
            'page_title': 'Bro APP',


            // Error page
            'error_title_404': 'Page was lost in space....<br> Are you sure that it exist?',


            // Keplr Error page
            'keplr_error_title': "Oooops, it seems you don't have<br> a Keplr extension",
            'keplr_reload_title': 'Please reload the page',


            // Main page
            'main_page_title': 'Advance Your Asset Management',
            'main_page_demo_text': 'For a brief set of instructions, please refer to the',
            'main_page_demo_link': 'Demo',


            // Create passport page
            'passport_avatar_label': 'Upload avatar',
            'passport_avatar_file_size': 'File up to 5 MB',
            'passport_avatar_mimetype_size': 'Only jpg, png',
            'passport_constitution_link': 'read constitution',
            'passport_name_label': 'Nickname',
            'passport_citizenship_label': 'Citizenship',
            'passport_citizenship_text': 'Moon<br> citizen',
            'passport_name_placeholder': 'Choose name',
            'passport_address_exp': 'Address',
            'passport_name_exp': 'Limit 8-16 characters; only a-z, 0-9, "-"',
            'passport_constitution_exp': 'Creating a passport I confirm reading the Constitution',
            'passport_error_nickname_title': 'Nickname misspelled',


            // Proposals page
            'proposals_empty_title': "The governor's desk is proposal-free, no action needed!",

            'proposal_date_label_default': 'Voting ended:',

            'proposal_deposite_label_collected': 'Collected funds:',
            'proposal_deposite_label_necessary': 'Necessary funds:',

            'proposal_voting_label_yes': 'Yes',
            'proposal_voting_label_no': 'No',
            'proposal_voting_label_nwv': 'NWV',
            'proposal_voting_label_abstain': 'Abstain',

            'proposal_vote_yes': 'Yes',
            'proposal_vote_no': 'No',
            'proposal_vote_nwv': 'No with veto',
            'proposal_vote_abstain': 'Abstain',
            'proposal_vote_did_not_vote': "Didn't vote",


            // Proposal page
            'proposal_proposer_label': 'Proposer',
            'proposal_tab1': 'Proposal data',
            'proposal_tab2': 'Votes',
            'proposal_desc_title': 'Description',
            'proposal_current_account_title': 'Account:',
            'proposal_current_vote_title': 'Your current vote:',
            'proposal_deposit_status_title': 'Deposit status',
            'proposal_details_title': 'Proposal Details',
            'proposal_vote_details_title': 'Vote details',

            'proposal_details_voting_label': 'Voting End Time',
            'proposal_details_deposit_label': 'Deposit End Time',
            'proposal_details_submit_label': 'Submit Time',
            'proposal_details_total_label': 'Total Deposit',
            'proposal_details_initial_label': 'Initial Deposit',

            'proposal_add_vote_exp': 'You need to change your Keplr account',

            'proposal_vote_info_final_status_label': 'Final Status',
            'proposal_vote_info_current_status_label': 'Current Status',
            'proposal_vote_info_status_passed': 'Passed',
            'proposal_vote_info_status_rejected': 'Rejected',
            'proposal_vote_info_status_passing': 'Passing',
            'proposal_vote_info_status_rejecting': 'Rejecting',
            'proposal_vote_info_turnout_label': 'Turnout',
            'proposal_vote_info_total_vote_label': 'Total Vote',
            'proposal_vote_info_minimum': 'Minimum of staked {amount} {denom} (40%) need to vote for this proposal to pass',

            'proposal_vote_yes_btn': 'Yes',
            'proposal_vote_no_btn': 'No',
            'proposal_vote_nwv_btn': 'No with veto',
            'proposal_vote_abstain_btn': 'Abstain',

            'proposal_vote_result_no_vote_label': 'Not voted',
            'proposal_vote_result_yes_label': 'yes',
            'proposal_vote_result_no_label': 'no',
            'proposal_vote_result_nwv_label': 'nwv',
            'proposal_vote_result_abstain_label': 'abstain',
            'proposal_vote_result_votes_label': 'votes',

            'proposal_feature_height_label': 'Height',
            'proposal_feature_info_label': 'Info',
            'proposal_feature_name_label': 'Name',
            'proposal_feature_time_label': 'Time',
            'proposal_feature_changes_key_label': 'Key',
            'proposal_feature_changes_subspace_label': 'Subspace',
            'proposal_feature_changes_value_label': 'Value',

            'proposal_votes_filter_all': 'All',
            'proposal_votes_filter_yes': 'Yes',
            'proposal_votes_filter_no': 'No',
            'proposal_votes_filter_nwv': 'No with veto',
            'proposal_votes_filter_abstain': 'Abstain',
            'proposal_votes_filter_null': 'Did not vote',
            'proposal_votes_filter_weighted': 'Weighted',

            'proposal_votes_th_validator': 'Validator',
            'proposal_votes_th_validator_vote': 'Validator Vote',
            'proposal_votes_th_most_voted': 'Most voted on',
            'proposal_votes_th_community_votes': 'Community Votes',
            'proposal_votes_th_percents': 'Participation<br> Rate',

            'proposal_votes_community_votes_tooltip': 'The ratio of specific votes to the total votes cast',
            'proposal_votes_percents_tooltip': 'Percentage of voters relative to the total number of delegators.',

            'proposal_votes_validator_no_active': 'no active',
            'proposal_votes_validator_sticker': 'Your Validator',

            'proposal_depositors_title': 'Depositors',
            'proposal_depositors_th_depositor': 'Depositor',
            'proposal_depositors_th_tx_hash': 'TxHash',
            'proposal_depositors_th_amount': 'Amount',
            'proposal_depositors_th_time': 'Time',


            // Account
            'account_networks_title': 'Networks',
            'account_abilities_title': 'Abilities',
            'account_validators_title': 'Validators',
            'account_proposals_title': 'Governance',
            'account_connected_addresses_title': 'My Portfolio',

            'account_connected_addresses_all': 'View all',

            'account_charts_tab_stake': 'Stake',
            'account_charts_tab_liquide': 'Liquid',
            'account_charts_tab_assets': 'Assets',
            'account_charts_tab_networks': 'Networks',
            'account_charts_tab_accounts': 'Accounts',

            'account_chart_stake_block_title': 'Stake Management',
            'account_chart_stake_desc': 'contain staked, liquid and unbonding tokens',

            'account_chart_liquide_block_title': 'Liquid Management',
            'account_chart_liquide_desc': 'contain liquid, rewards and IBC tokens',

            'account_chart_networks_block_title': 'Network Management',
            'account_chart_networks_desc': 'represent all networks in portfolio',

            'account_chart_assets_block_title': 'Asset Management',
            'account_chart_assets_desc': 'represent all tokens in portfolio',

            'account_chart_accounts_block_title': 'Account Management',
            'account_chart_accounts_desc': 'contain your linked accounts',

            'account_charts_staked_label': 'Staked',
            'account_charts_outside_label': 'Outside',
            'account_charts_liquid_tokens_label': 'Liquid',
            'account_charts_unbonding_label': 'Unbondend',
            'account_charts_ibc_label': 'IBC Tokens',
            'account_charts_rewards_label': 'Rewards',
            'account_charts_on_chain': 'On',

            'account_charts_total_assets': 'Total Assets',

            'account_validators_col_account_name': 'Account name',
            'account_validators_col_network': 'Network',
            'account_validators_col_validator': 'Validator',
            'account_validators_col_account_percent': 'Account %',
            'account_validators_col_passport_percent': 'Passport %',
            'account_validators_empty_text': 'You have no active validators',

            'account_proposals_col_network': 'Network',
            'account_proposals_col_name': 'Proposal name',
            'account_proposals_col_type': 'Type',
            'account_proposals_col_status': 'Status',

            'account_proposals_status_all': 'All proposals',
            'account_proposals_status_deposite': 'Deposit period',
            'account_proposals_status_voting': 'Voting period',
            'account_proposals_status_passed': 'Passed',
            'account_proposals_status_rejected': 'Rejected',

            'account_proposals_suspicious_tooltip': 'Suspicious',

            'account_RPDE': 'Daily profit',
            'account_APR': 'Personal APR',
            'account_rewards': 'Rewards',
            'account_passport_value': 'Passport Value',

            'account_copy_tooltip': 'Address copied',
            'account_duplicate_ext': 'Duplicated address',
            'account_not_supported_ext': 'Not supported',


            // Filter
            'filter_title': 'Filter',


            // Demo notice
            'demo_notice_text': 'Finish Demo session',


            // Footer
            'footer_contact': 'Contact',
            'footer_support': 'Support',
            'footer_powered': 'Powered by the Spacebox Index',


            // Feedback
            'feedback_title': 'Report a bug here',
            'feedback_desc': 'Share it with us',
            'feedback_telegram_link': 'Telegram',
            'feedback_github_link': 'Github',


            // Under construction page
            'under_construction_title': 'This part of site is under construction',


            // Mobile plug
            'mobile_plug_title': 'Oops!',
            'mobile_plug_desc': "Bro App not yet supported on mobile devices. Please use a desktop or laptop. We're working on it! Thank you for your patience.",


            // Notofications
            'notice_default_main_page': 'Welcome to the best Cosmos managing tool.',
            'notice_default_create_passport': 'To create your passport, please ensure that you upload the information correctly. Then you will be asked to sign the transaction.',
            'notice_default_create_passport_success': 'Congrats! You have successfully created a Passport. You can now continue the journey and explore the app in BETA 2.0.',
            'notice_default_constitution_modal': "By clicking 'Agree & Sign' you confirm that you have read the Constitution in full and wish to create a passport. After clicking 'Agree & Sign' you will be asked to sign the transaction.",
            'notice_default_account_passport': 'That’s your recently created passport. You can now change the image or download the passport.',
            'notice_default_proposals_page': 'All proposals including proposals in Deposit period can be found on this page.',
            'notice_default_account_page': 'Welcome to our portfolio management tool. Take control of your investments and optimize your portfolio for maximum returns.',
            'notice_default_proposal_page': 'Detailed overview for the selected proposal.',

            'notice_demo_default_account_page': 'Provides valuable information regarding your interactions inside the app.',

            'notice_ipfs_active': "The IPFS node is built in right in your browser. It helps store and resolves the content from the distributed storage. NFTs, moon passports, ENS, with contents address, can be resolved right here. It is green because the IPFS node is active and resolves the content.",
            'notice_ipfs_not_active': "The IPFS node is built in right in your browser. It helps store and resolves the content from the distributed storage. NFTs, moon passports, ENS, with contents address, can be resolved right here. It is red because the IPFS node is inactive and not resolves the content.",

            'notice_currency': "The currency for the account valuation",
            'notice_username': "Chosen keplr account",
            'notice_avatar': "Your Moon passport avatar",

            'notice_delete_address': 'To Delete linked account please press on “delete icon” for selected address and then confirm your actions.',
            'notice_edit_address': 'To Edit linked account please press on “edit icon” for selected address and then confirm your actions.',
            'notice_copy_address': 'To Copy linked account please press on “copy icon” for selected address.',

            'notice_account_charts_tab_stake': 'The Stake Management tab shows how many staked, liquid, and unbonding tokens you have in your account, providing a comprehensive view of your current staking activity.',
            'notice_account_charts_tab_liquide': 'The Liquid Management tab shows all of your liquid tokens for the selected networks, on both Outside and IBC channels, providing a detailed overview of your current holdings.',
            'notice_account_charts_tab_assets': 'The Asset Management tab represents all networks and offers a complete view of your entire portfolio',
            'notice_account_charts_tab4': 'The Network Management tab represents the value of all tokens in your portfolio and provides a complete overview on network level. ',
            'notice_account_charts_tab_accounts': 'The Account Management tab provides a detailed breakdown of all your linked accounts, offering a complete overview for each.',

            'notice_add_address_step1': 'Please select a new address in Keplr to be able to link it to the portfolio.',
            'notice_add_address_step2': 'Select the right address that you want to add to the portfolio.',
            'notice_add_address_step2_error': 'The address you have selected already linked to your portfolio, please switch to another account.',
            'notice_add_address_step3': 'To approve selected address and confirm the change you need to sign a transaction.',
            'notice_add_address_step4': 'To complete the process, you need to switch to your passport account in Keplr.',
            'notice_add_address_step5': 'To finish the process and finalize adding a new account please sign a broadcast transaction.',
            'notice_add_address_success': 'You have successfully added new wallet address to your portfolio.',


            // IPFS
            'ipfs': 'IPFS node',


            // Constitution modal
            'constitution_modal_title': 'Constitution',


            // Deposit modal
            'deposit_modal_title': 'Deposit',
            'deposit_modal_sender_label': 'Sender',
            'deposit_modal_amount_label': 'Amount available',


            // Add address modal
            'add_address_modal_title': 'Add new account',
            'add_address_modal_error_desc': 'To add a new address — select a different Keplr account',
            'add_address_modal_error_desc2': 'This wallet is already added to portfolio',
            'add_address_modal_error_desc3': 'This wallet has its own unique passport',

            'add_address_modal_step1_name': 'Choose Address',
            'add_address_modal_step2_name': 'Approve Address',
            'add_address_modal_step3_name': 'Switch Account',
            'add_address_modal_step4_name': 'Accounts Linked',

            'add_address_modal_step1_title': 'Select the address',
            'add_address_modal_step2_title': 'To approve the address you need to sign the transaction.',
            'add_address_modal_step3_title': 'Switch to passport account in Keplr',
            'add_address_modal_step4_title': 'Almost there...',
            'add_address_modal_step5_title': 'Bravo! Congrats!',

            'add_address_added_label': 'Added',
            'add_address_duplicated_label': 'Duplicated',


            // Validator modal
            'validator_modal_title': 'Validator details',

            'validator_modal_website_btn': 'Website',
            'validator_modal_mintscan_btn': 'Mintscan',
            'validator_modal_broscore_btn': 'Bro Score',

            'validator_modal_active_set': 'Active',
            'validator_modal_inactive_set': 'Inactive',

            'validator_modal_operator_address_label': 'Operator Address',
            'validator_modal_details_label': 'Details',

            'validator_modal_commission_label': 'Commission',
            'validator_modal_ownership_label': 'Self Bonded/<br>Ownership',
            'validator_modal_total_delegated_label': 'Total delegated amount',
            'validator_modal_voted_label': 'Props voted',

            'validator_modal_col_commission_notice': "The percentage of validator's commision.",
            'validator_modal_col_self_bonded_ownership_notice': 'The number of tokens delegated to yourself. The ratio of the number of tokens delegated to yourself to the total number of delegations.',
            'validator_modal_col_voted_notice': 'This criterion is about the number of votes ({voted}) for the last 5 ended proposals.',
            'validator_modal_col_total_delegated_notice': 'Total amount of tokens delegated to the validator',


            // Notofications
            'notification_progress_title': 'Transaction is in progress',
            'notification_successful_title': 'Transaction successful',
            'notification_failed_title': 'Transaction failed',
            'notification_explorer_link': 'Explorer',
            'notification_error_file_size_title': 'Error',
            'notification_error_file_size_text': 'Selected image is too large. Please select new < {size}',
            'notification_error_nickName_title': 'Nickname error',
            'notification_error_nickName_desc': 'There is already a passport for this name, come up with a new one',
            'notification_passport_signature': 'Constitution successfully signed',
            'notification_passport_activation_process': 'Account activation...',
            'notification_passport_activation_success': 'Account successfully activated',
            'notification_passport_activation_error': 'Account activation error',
            'notification_passport_activation_error_desc': 'Try again',
            'notification_passport_wait_ipfs': 'Please, wait while local IPFS node is starting.',

            'notification_action_create_passport': 'Create passport',
            'notification_passport_create_process': 'Passport creation...',
            'notification_success_create_passport_title': 'Passport created successfully',
            'notification_error_create_passport_title': 'Passport issue error',

            'notification_action_update_passport': 'Update passport',
            'notification_action_delete_passport': 'Delete passport',
            'notification_passport_update_process': 'Passport updating...',
            'notification_passport_delete_process': 'Passport deleting...',
            'notification_success_update_passport_title': 'Passport updated successfully',
            'notification_success_delete_passport_title': 'Passport delete successfully',
            'notification_error_update_passport_title': 'Passport issue error',

            'notification_action_address_delete': 'Address delete',
            'notification_address_deleting_process': 'Address deleting...',
            'notification_success_address_delete_title': 'Address deleted successfully',

            'notification_action_address_add': 'Address add',
            'notification_address_adding_process': 'Processing...',
            'notification_success_address_added_title': 'Address added successfully',

            'notification_action_address_rename': 'Address rename',
            'notification_address_renaming_process': 'Address renaming...',
            'notification_success_address_rename_title': 'Address renamed successfully',

            'notification_action_proposal_vote': 'Proposal vote',
            'notification_proposal_vote_process': 'Proposal voiting...',

            'notification_proposal_deposit_process': 'Proposal depositing...',
            'notification_success_proposal_deposit_title': 'Proposal deposit successful',
            'notification_action_proposal_deposit': 'Proposal deposit',

            'notification_tx_error_2': "tx parse error",
            'notification_tx_error_3': "invalid sequence",
            'notification_tx_error_4': "unauthorized",
            'notification_tx_error_5': "insufficient funds",
            'notification_tx_error_6': "unknown request",
            'notification_tx_error_7': "invalid address",
            'notification_tx_error_8': "invalid pubkey",
            'notification_tx_error_9': "unknown address",
            'notification_tx_error_10': "invalid coins",
            'notification_tx_error_11': "Broadcasting transaction failed: out of gas try again and increase gas amount",
            'notification_tx_error_12': "memo too large",
            'notification_tx_error_13': "insufficient fee",
            'notification_tx_error_14': "maximum number of signatures exceeded",
            'notification_tx_error_15': "no signatures supplied",
            'notification_tx_error_16': "failed to marshal JSON bytes",
            'notification_tx_error_17': "failed to unmarshal JSON bytes",
            'notification_tx_error_18': "invalid request",
            'notification_tx_error_19': "tx already in mempool",
            'notification_tx_error_20': "mempool is full",
            'notification_tx_error_21': "tx too large",
            'notification_tx_error_22': "key not found",
            'notification_tx_error_23': "invalid account password",
            'notification_tx_error_24': "tx intended signer does not match the given signer",
            'notification_tx_error_25': "invalid gas adjustment",
            'notification_tx_error_26': "invalid height",
            'notification_tx_error_27': "invalid version",
            'notification_tx_error_28': "invalid chain-id",
            'notification_tx_error_29': "invalid type",
            'notification_tx_error_30': "tx timeout height",
            'notification_tx_error_31': "unknown extension options",
            'notification_tx_error_32': "incorrect account sequence",
            'notification_tx_error_33': "failed packing protobuf message to Any",
            'notification_tx_error_34': "failed unpacking protobuf message from Any",
            'notification_tx_error_35': "internal logic error",
            'notification_tx_error_36': "conflict",
            'notification_tx_error_37': "feature not supported",
            'notification_tx_error_38': "not found",
            'notification_tx_error_39': "Internal IO error",
            'notification_tx_error_40': "error in app.toml",
            'notification_tx_error_41': "invalid gas limit",

            'notification_tx_error_rejected': "The transaction has been rejected",


            // Tours
            'tour_header_title_step0': 'Tooltip',
            'tour_header_title_step1': 'Portfolio Currency',
            'tour_header_title_step2': 'Your Digital Identity',

            'tour_account_page_title_step1': 'Networks',
            'tour_account_page_title_step2': 'Bundled Wallets',
            'tour_account_page_title_step3': 'Assets Management',
            'tour_account_page_title_step4': 'Portfolio Insights',
            'tour_account_page_title_step5': 'Validators',
            'tour_account_page_title_step6': 'Governance',

            'tour_header_desc_step0': 'Provides valuable information regarding your interactions inside the app',
            'tour_header_desc_step1': 'Allows you to conveniently change between different currencies and view portfolio evaluations within the app',
            'tour_header_desc_step2': 'Allows you to authenticate yourself, securely interact with others, and engage in transactions with confidence',

            'tour_account_page_desc_step1': 'Enables you to view info and seamlessly switch between different blockchain networks',
            'tour_account_page_desc_step2': 'Allows you to consolidate and manage multiple wallets in one place; it may include duplicates',
            'tour_account_page_desc_step3': 'Provides detailed view of all your digital assets on different levels',
            'tour_account_page_desc_step4': 'Offers an overview of key performance indicators and average numbers for your portfolio',
            'tour_account_page_desc_step5': 'Displays the number of validators you stake with on one or more networks',
            'tour_account_page_desc_step6': 'Lets you navigate through proposals, view their descriptions, vote and see votes breakdown',


            // Buttons
            'btn_back_home': 'Back to home',
            'btn_reload': 'Reload',
            'btn_connect_wallet': 'Connect Keplr wallet',
            'btn_reject': 'Reject',
            'btn_confirm': 'Confirm',
            'btn_agree_sign': 'Agree & Sign',
            'btn_keplr_install': 'Install Keplr',
            'btn_add_address': 'Add address',
            'btn_use_new_passport': 'Use new passport',
            'btn_next': 'Next',
            'btn_back': 'Back',
            'btn_sign': 'Sign',
            'btn_sing_broadcast': 'Sign and Broadcast',
            'btn_done': 'Done',
            'btn_see_more': 'See more',
            'btn_no': 'No',
            'btn_yes': 'Yes',
            'btn_download_png': 'Dowloand png',
            'btn_continue': 'Continue journey',
            'btn_update': 'Update',
            'btn_change_image': 'Change image',
            'btn_settings': 'Setting',
            'btn_create_passport': 'Create passport',
            'btn_refresh': 'Refresh',
            'btn_deposit': 'Deposit',
            'btn_send': 'Send',
        }
    }
}