const network = {
    name: 'G-Bridge',
    lcd_api: 'https://lcd.gravity-bridge-3.bronbro.io',
    rpc_api: 'https://rpc.gravity-bridge-3.bronbro.io',

    denom: 'ugraviton',
    token_name: 'GRAV',
    chainId: 'gravity-bridge-3',
    mintscanAlias: 'gravity-bridge',
    prefix: 'gravity',
    address_prefix: 'gravity',
    exponent: 6,
    proposal_need: 250
}

export default network