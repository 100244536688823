const network = {
    name: 'Crescent hub',
    lcd_api: 'https://lcd.crescent-1.bronbro.io',
    rpc_api: 'https://rpc.crescent-1.bronbro.io',

    denom: 'ucre',
    token_name: 'CRE',
    chainId: 'crescent-1',
    mintscanAlias: 'crescent',
    prefix: 'crescent',
    address_prefix: 'cre',
    exponent: 6,
    proposal_need: 250
}

export default network