const network = {
    name: 'OmniFlix Network',
    lcd_api: 'https://lcd.omniflixhub-1.bronbro.io',
    rpc_api: 'https://rpc.omniflixhub-1.bronbro.io',

    denom: 'uflix',
    token_name: 'FLIX',
    chainId: 'omniflixhub-1',
    mintscanAlias: 'omniflix',
    prefix: 'omniflix',
    address_prefix: 'omniflix',
    exponent: 6,
    proposal_need: 250
}

export default network